import { css } from "@emotion/react";
import { colors, mediaQuery, spacing, fontSizes, lineHeights } from "../../../../core/styles";

export const styles = {
  contentWrapper: (withImage) =>
    css({
      maxWidth: "528px",
      [mediaQuery.desktopOrLarger]: {
        maxWidth: withImage ? "528px" : "712px",
      },
      h2: {
        marginTop: 0,
      },
    }),

  textWrapper: (title) =>
    css({
      color: colors.headlineGrey.hex,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      marginTop: title ? "0" : spacing.baseUnitMobile3,
      [mediaQuery.desktopOrLarger]: {
        marginTop: "0",
      },
      a: {
        textDecoration: "underline",
      },
    }),

  buttonWrapper: () =>
    css({
      textAlign: "center",
      paddingTop: spacing.baseUnitMobile3,
    }),
};
